.project__article {
    display: flex;
    flex-direction: column;
    width: 47vw;
    height: auto;
    border-radius: 1rem;
    margin: auto;
    margin-top: 2rem;
    box-shadow: var(--shadow)
}
.project__article-heading {
    font-family: var(--font-family);
    width: 100%;
    text-align: left;
    margin-top: 1rem;
}
.project__article-heading h1 {
    font-weight: 800;
    font-size: 40px;
    color: var(--primary-text);
    letter-spacing: -0.04em;
    margin-bottom: 1rem;
}
.project__article-heading p {
    font-size: 12px;
    text-align: left;
}
.project__article-image img {
    width: 100%;
    margin: 0 0 2rem;
}
.project__article-content {
    text-align: left;
    font-family: var(--font-family);
    margin-bottom: 2rem;
}
.project__article-content h2, .project__article-content h3 {
    margin-bottom: 0.7rem;
}
.project__article-content h2 {
    margin-top: 3rem;
}
.project__article-content p {
    line-height: 1.35;
}
.metadescription p {
    font-size: 18px;
}