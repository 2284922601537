.project__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-footer);
    margin-top: 5rem;
}
.project__footer-heading {
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;
}
.project__footer-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    color: var(--primary-text);
    font-size: 62px;
    line-height: 75px;
    text-align: center;
    letter-spacing: -0.04em;
}
.project__footer-newsletter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40vw;
    height: 20vh;
}
.project__footer-newsletter input {
    flex: 0.3;
    height: 40px;
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 27px;
    border: 2px solid var(--primary-text);
    padding: 0 1rem;
    outline: none;
}
.project__footer-newsletter button {
    flex: 0.3;
    height: 40px;
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    background: #2049ff;
    color: white;
    cursor: pointer;
    outline: none;
    border: none;
    margin-top: 1rem;
}
.project__footer-smalltext {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1rem;
    border: 1px solid #fff;
    text-align: center;

    margin-bottom: 10rem;
    cursor: pointer;
}
.project__footer-smalltext p {
    box-sizing: border-box;
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 21px;
    word-spacing: 2px;
}
.project__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;

    width: 100%;
    text-align: left;
}
.project__footer-links div {
    width: 250px;
    margin: 1rem;
}
.project__footer-links_logo {
    display: flex;
    flex-direction: column;
}
.project__footer-links_logo img {
    width: 118px;
    height: 30px;

    margin-bottom: 1rem;
}
.project__footer-links_logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
}
.project__footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
.project__footer-links_div h4 {
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 16px;

    margin-bottom: 1rem;
}
.project__footer-links_div p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;

    margin: 0.5rem 0;
    cursor: pointer;
}
.project__footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}
.project__footer-copyright p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
}
@media screen and (max-width: 850px) {
    .project__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}
@media screen and (max-width: 550px) {
    .project__footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }
    .project__footer-links_div {
        margin: 1rem 0;
    }
    .project__footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }
}
@media screen and (max-width: 400px) {
    .project__footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
}