@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Roboto', sans-serif;

  --primary-text: rgba(41, 41, 41, 1);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --blue: #2049ff;
  --grey: #EDEFF1ff;
  --shadow: 0 20px 50px -10px rgba(59, 59, 59, 0.35)
}